import React from "react";
import { graphql, HeadFC, Link } from "gatsby";
import Button from "../../../components/elements/Button/Button";
import Calendar from "../../../components/elements/Calendar/Calendar";
import Layout from "../../../components/layouts/Default/Default";
import { PageHeaderWithGakka } from "../../../components/elements/PageHeader/PageHeader";
import {
  CalendarSourceArray,
  SliceCalendarLimit,
} from "../../../utils/date/date";
import * as calendar from "../../../json/openCampus.json";
import { useGakka } from "../../../hooks/useGakka/useGakka";
import { CommonHead } from "../../../components/commons/Head/Head";
import { ResponsiveBlock } from "../../../components/layouts/ResponsiveBlock/ResponsiveBlock";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
  }
`;

interface CalendarProps {
  calendarDates: CalendarSourceArray;
  courseTitle: any; // TODO:
  limit: SliceCalendarLimit;
  formInquiryType: string;
  invertGakkaPOSonTitle?: boolean;
  openTime1: string;
  openTime2?: string;
  bgColor: string;
  text1: string;
  text2: string;
  formPath: string;
}

const LessonCalPage = ({ data }: any) => {
  const gakka = useGakka();
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  const backPath = "/comprehensive/opencampus";
  const calendarProps: CalendarProps = {
    calendarDates: calendar.calendar?.online as CalendarSourceArray,
    courseTitle: gakka.title,
    limit: 60,
    formInquiryType: "オンライン説明会",
    invertGakkaPOSonTitle: true,
    openTime1: "AM 10:00〜",
    openTime2: "PM 14:30〜",
    bgColor: gakka.color,
    text1:
      "遠方の受験生を対象に、リアルOC配信を視聴しながら自宅で学科相談もできる「オンラインLIVE★オープンキャンパス」を土日中心に開催中！",
    text2: "ご希望の日付を選択してください",
    formPath: "/application-form/",
  };
  return (
    <Layout title={title} pageType="SubPage">
      <div className="tw-c-lesson-cal">
        <PageHeaderWithGakka title={calendarProps.formInquiryType} />
        <Calendar {...calendarProps}></Calendar>
        <div className="tw-py-8 tw-text-center">
          <Button url={backPath} name="戻る" />
        </div>
      </div>
    </Layout>
  );
};

export default LessonCalPage;

export const Head: HeadFC = ({ data }: any) => {
  return (
    <CommonHead
      ogTitle={`オンライン説明会 - 総合英語科 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    >
      <title>
        オンライン説明会 - 総合英語科 -
        {data.allSettingsJson?.nodes[0].siteTitle}
      </title>
    </CommonHead>
  );
};
